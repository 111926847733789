<template>
    <div class="ticket-container" :class="{ compressed: compressed, selected: selected, portrait: ['portrait'].includes($mq) }">
        <div class="ticket" @click="openTicket()">
            <div class="left">
                <div class="top">
                    <div class="tag" :style="{ 'background-color': statusColor }">{{ $t(getStatusName(ticket.data.status)) }}</div>
                    <div class="date">{{ createdDate }}</div>
                    <div class="message" v-if="ticket.status == 2">
                        <template v-if="!compressed">{{ $t('tickets.new_answer') }}</template>
                    </div>
                </div>
                <div class="bottom">
                    <!-- <div class="name">#{{ ticket.ticket_number }}</div> -->
                    <div class="description">
                        <span class="name">#{{ ticket.ticket_number }}</span>
                        {{ ticket.description }}
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="arrow"></div>
            </div>
        </div>
    </div>
</template>

<script>
import UserTag from '@/components/layout/UserTag'

export default {
    name: 'TicketItem',
    components: {
        UserTag
    },
    props: {
        ticket: { type: Object, default: {} },
        statusColor: { type: String, default: '' },
        compressed: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            userName: false
        }
    },
    computed: {
        createdDate() {
            let date = moment(this.ticket.created_date * 1000)
            let result = moment(date).format('DD MMM HH:mm')
            return result
        },

        employee() {
            let employee = this.$store.getters['employee/getEmployee'](this.ticket.employee_id)
            return employee && typeof employee !== 'undefined' ? employee : false
        }
    },
    methods: {
        openTicket() {
            this.$emit('openTicket', this.ticket.id)
        },
        getStatusName(status) {
            const statusList = ['all', 'Open', 'Hold', 'Closed']

            if (!statusList.includes(status)) {
                status = 'Open'
            }

            status = status.toLowerCase()

            return `tickets.status.${status}`
        }
    },
    created() {
        // check if ticket has employee id
        if (!this.ticket.employee_id) {
            this.userName = this.ticket.data.name
        }
    }
}
</script>

<style lang="scss">
.ticket-container {
    @include display-flex();
    @include justify-content();
    @include align-items();
    @include flex-wrap(wrap);
    flex-grow: 1;
    height: fit-content;
    width: 100%;
    margin-bottom: 12px;

    &.selected {
    }

    .ticket {
        @include border-radius(4px);
        width: 100%;
        box-shadow: (0px 0px 10px rgba(221, 221, 221, 0.5));
        cursor: pointer;
        overflow: hidden;
        @include background($color: $neutro-t90);

        .left {
            @include display-flex();
            @include justify-content(flex-start);
            @include align-items(flex-start);
            @include align-content(flex-start);
            @include flex-wrap(wrap);
            width: calc(100% - 40px);
            height: auto;
            padding: 7px 12px;

            .top {
                @include display-flex();
                // @include justify-content(flex-start);
                // @include align-items();
                @include flex-wrap(wrap);
                // @include background($image: img('chatbox_inactive.svg'), $size: 20px, $position: top right);
                width: 100%;
                position: relative;

                .ball {
                    border-radius: 12px;
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;
                    background-clip: padding-box;
                }

                .tag {
                    @include border-radius(3px);
                    @include font-size(xs);
                    font-family: $conden-bold;
                    color: #fff;
                    // padding: 0 10px;
                    text-align: center;
                    margin-right: 10px;
                    width: 85px;
                }

                .date {
                    @include background($image: img('input_datetime_neutro_s50.svg'), $size: 15px, $position: left center);
                    @include font-size(s);
                    @include display-flex();
                    @include justify-content(flex-start);
                    @include align-items();
                    @include flex-wrap(wrap);
                    color: $neutro-s50;
                    font-family: $conden;
                    width: fit-content;
                    padding-left: 20px;
                    height: 50%;
                }

                .name {
                    @include font-size(ml);
                    font-family: $text-bold;
                    color: $neutro-s90;
                }

                .message {
                    @include border-radius(3px);
                    @include font-size(xs);
                    @include background($color: $warning);
                    font-family: $conden-bold;
                    color: #fff;
                    text-align: center;
                    padding: 0 10px;
                    width: fit-content;
                    position: absolute;
                    right: 0;
                }

                // &.newMessages {
                //     @include background($image: img('chatbox_warning_s10.svg'), $size: 20px, $position: top right);
                // }
            }

            .bottom {
                @include display-flex();
                // @include justify-content(flex-start);
                @include align-items(flex-start);
                @include flex-wrap(wrap);
                width: 100%;

                .name {
                    @include display-inline-flex();
                    @include justify-content(flex-end);
                    @include font-size(sm);
                    font-family: $text-bold;
                    color: $neutro-s70;
                    width: 85px;
                    padding-right: 6px;
                }

                .description {
                    @include text-ellipsis();
                    @include font-size(sm);
                    // @include display-flex();
                    display: inline-block;
                    color: $neutro-s70;
                    font-family: $text;
                    width: calc(100%);
                    white-space: nowrap;
                    overflow: hidden;
                    padding-left: 6px;
                }
            }
        }

        .right {
            @include display-flex();
            @include justify-content();
            @include align-items(flex-start);
            @include flex-wrap(wrap);
            @include background($color: $neutro-t90);
            @include background($image: img('right_neutro.svg'), $size: 13px, $position: center center);
            width: 40px;
            height: 70px;
            padding: 3px 12px;
        }
    }
    // COMPRESSED VERSION
    &.compressed {
        margin-bottom: 20px;
        .ticket {
            box-shadow: unset;
            .left {
                padding: 6px;
                width: 100%;
                background-color: $inactive-t70;

                .top {
                    @include display-flex();
                    @include justify-content(flex-start);
                    @include align-items();

                    .tag {
                        width: fit-content;
                        padding: 0px 6px;
                    }

                    .date {
                        @include font-size(xs);
                    }

                    .message {
                        @include background($image: img('menu_ffffff.svg'), $size: 10px, $position: center center);
                        width: 14px;
                        height: 14px;
                        padding: 0;
                    }
                }
                .bottom {
                    margin-top: 3px;
                    .name {
                        @include justify-content(flex-start);
                        @include font-size(s);
                        width: fit-content;
                    }
                    .description {
                        @include font-size(s);
                        @include text-ellipsis($line: 2);
                        white-space: initial;
                        width: auto;
                        padding: 0;
                    }
                }
            }

            .right {
                display: none;
            }
        }
        &.selected {
            .ticket {
                box-shadow: (0px 0px 10px rgba(221, 221, 221, 0.5));
                background-color: #fff;

                .left {
                    background-color: #fff;
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.ticket-container.portrait {
    margin-bottom: 10px;

    .ticket {
        background-color: #fff;
        box-shadow: none;
        .left {
            background-color: initial;
            .top {
                .ball {
                }

                .tag {
                }

                .date {
                    @include font-size(xs);
                }

                .name {
                }

                .message {
                }
            }

            .bottom {
                .name {
                }

                .description {
                    @include font-size(s);
                }
            }
        }
    }
}
</style>

<template>
    <div class="ticketForm" :class="[$mq]">
        <!-- Title -->
        <!-- <div class="titl">{{ $t('tickets.title') }}</div> -->

        <!-- Tooltip -->
        <div class="item helpcenter_tooltip">
            <div class="info">
                <div class="left">
                    <div class="text">{{ $t('tickets.tooltip.part1') }}</div>
                    <div class="text">{{ $t('tickets.tooltip.part2') }}</div>
                </div>
                <div class="right">
                    <div class="button" @click="openHelpCenter()">{{ $t('tickets.tooltip.helpcenter') }}</div>
                </div>
            </div>
        </div>

        <!-- Tool -->
        <div class="item tool">
            <div class="label">{{ $t('tickets.tool') }}</div>
            <div class="selector">
                <select v-model="selectedTool">
                    <option v-for="option in options" :key="option.key" :value="option.key">{{ option.value }}</option>
                </select>
            </div>
        </div>

        <!-- Description -->
        <div class="item description">
            <div class="label">{{ $t('tickets.description.title1') }}</div>
            <div class="sublabel">{{ $t('tickets.description.title2') }}</div>
            <div class="textarea">
                <textarea class="scrollbar" v-model.trim="description" :placeholder="$t('tickets.description.placeholder')"></textarea>
            </div>
        </div>

        <!-- Email -->
        <div class="item email">
            <div class="label">{{ $t('tickets.email') }}</div>
            <div class="label-error" v-if="invalidEmail">
                <div class="text">{{ $t('tickets.invalid_email') }}</div>
            </div>
            <div class="input">
                <input v-model.trim="email" :placeholder="$t('tickets.email_placeholder')" />
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'TicketForm',
    props: {},
    data() {
        return {
            options: {
                labelling: {
                    key: 'labelling',
                    value: i18n.t('tickets.options.labelling')
                },
                library: {
                    key: 'library',
                    value: i18n.t('tickets.options.library')
                },
                'tasks-and-records': {
                    key: 'tasks-and-records',
                    value: i18n.t('tickets.options.tasks-and-records')
                },
                printer: {
                    key: 'printer',
                    value: i18n.t('tickets.options.printer')
                },
                audits: {
                    key: 'audits',
                    value: i18n.t('tickets.options.audits')
                },
                incidents: {
                    key: 'incidents',
                    value: i18n.t('tickets.options.incidents')
                },
                'users-and-login': {
                    key: 'users-and-login',
                    value: i18n.t('tickets.options.users-and-login')
                },
                tablet: {
                    key: 'tablet',
                    value: i18n.t('tickets.options.tablet')
                }
            },
            selectedTool: -1,
            description: '',
            email: '',
            invalidEmail: false
        }
    },
    computed: {
        isApp() {
            return this.$store.getters['getIsApp']
        }
    },
    methods: {
        openHelpCenter() {
            if (this.isApp) {
                cordova.InAppBrowser.open('https://help.andyapp.io/', '_system')
            } else {
                window.open('https://help.andyapp.io/', '_blank')
            }
        }
    },
    created() {
        let self = this

        // reset bar acction
        this.$bar.reset()

        // send ticket (disabled)
        this.$bar.addAction('send-ticket', {
            callback: () => {},
            label: i18n.t('tickets.send')
        })

        // cancel => return to ticket list
        this.$bar.addAction('cancel', {
            callback: () => {
                self.$emit('cancel')
            },
            label: i18n.t('tickets.cancel'),
            customClass: 'left'
        })

        // check if location has email
        let location = this.$store.getters['loginUser/getLocalLocation']

        this.email = typeof location.email !== 'undefined' && location.email ? location.email : ''
    },
    updated() {
        let self = this
        let changeActions = true

        // validate tool
        if (this.selectedTool == -1) {
            changeActions = false
        }

        // validate description
        if (this.description == '') {
            changeActions = false
        }

        // validate email
        if (this.email == '') {
            changeActions = false
        }

        this.$bar.reset()

        if (changeActions) {
            // send ticket (ok)
            this.$bar.addAction('send-ticket-ok', {
                callback: () => {
                    // validate email format
                    if (self.email != '' && !validEmail(self.email)) {
                        self.invalidEmail = true
                    } else {
                        self.invalidEmail = false

                        // show loader
                        self.$overlay.loading({
                            blocking: true
                        })

                        // add ticket
                        self.$store
                            .dispatch('ticket/addTicket', {
                                location_id: self.$store.getters['loginUser/getLocalLocation'].id,
                                employee_id: self.$store.getters['loginUser/getLocalEmployee'],
                                tool: self.selectedTool,
                                description: self.description,
                                email: self.email,
                                language: self.$store.getters['getLanguage']
                            })
                            .then(function (response) {
                                self.$store.dispatch('ticket/loadList', { location_id: self.$store.getters['loginUser/getLocalLocation'].id })

                                // close loader
                                self.$overlay.hide()

                                // return to list
                                self.$emit('cancel')

                                // show tooltip
                                self.$snackbar.success({ message: self.$t('tickets.success'), duration: 5000, closeable: true })
                            })
                    }
                },
                opened: true,
                label: i18n.t('tickets.send')
            })

            // cancel => return to ticket list
            this.$bar.addAction('cancel', {
                callback: () => {
                    self.$emit('cancel')
                },
                customClass: 'left'
            })
        } else {
            // send ticket (disabled)
            this.$bar.addAction('send-ticket', {
                callback: () => {},
                label: i18n.t('tickets.send')
            })

            // cancel => return to ticket list
            this.$bar.addAction('cancel', {
                callback: () => {
                    self.$emit('cancel')
                },
                customClass: 'left'
            })
        }
    }
}
</script>

<style lang="scss">
.ticketForm {
    max-width: 1000px;
    padding: 30px 30px 30px 80px;

    .titl {
        @include font-size(xl);
        font-family: $text-bold;
        color: $main-s30;
        margin-bottom: 30px;
    }

    .item {
        margin-bottom: 30px;

        .label {
            @include font-size(m);
            font-family: $text;
            color: $neutro-s90;
        }

        .label-error {
            @include display-flex();
            @include justify-content(flex-start);
            @include align-items();
            @include flex-wrap(wrap);
            height: 40px;
            width: auto;

            .text {
                @include border-radius(4px);
                @include background($color: $error-light);
                @include font-size(ml);
                color: $error-s70;
                font-family: $conden;
                padding: 6px 9px;
                margin: 0;
            }
        }

        &.helpcenter_tooltip {
            @include display-flex();
            @include justify-content(flex-start);
            @include align-items();
            @include flex-wrap(wrap);

            .info {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include flex-wrap(wrap);
                @include background($color: $info-t30);
                @include border-radius(4px);
                box-shadow: 0px 0px 6px rgba(221, 221, 221, 0.75);
                max-width: 800px;
                width: auto;
                padding: 9px 12px;

                .left {
                    min-height: 48px;
                    height: auto;
                    padding-right: 9px;
                    .text {
                        @include font-size(ml);
                        font-family: $conden;
                        color: $info-s70;
                    }
                }

                .right {
                    .button {
                        @include interaction();
                        @include background($color: $neutro-t60);
                        @include font-size(ml);
                        @include border-radius(4px);
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);
                        font-family: $conden;
                        color: $info-s90;
                        height: 40px;
                        padding: 8px 12px;
                        @include bgHover($neutro-t60);
                    }
                }
            }
        }

        &.tool {
            .label {
                margin-bottom: 6px;
            }

            .selector {
                select {
                    @include background($image: img('down_caret_neutro_s90.svg'), $position: center right 15px, $size: 10px);
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $neutro-s90;
                    height: 40px;
                    width: 280px;

                    option {
                        @include font-size(m);
                        font-family: $text-bold;
                        color: $neutro-s90;
                    }
                }
            }
        }

        &.description {
            .label {
            }

            .sublabel {
                @include font-size(s);
                font-family: $text;
                color: $neutro-s70;
            }

            .textarea {
                textarea {
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $neutro-s90;
                    height: 90px;
                    width: 694px;

                    &::placeholder {
                        font-family: $text;
                        color: $neutro-s30;
                    }
                }
            }
        }

        &.email {
            .input {
                input {
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $neutro-s90;
                    height: 40px;
                    width: 280px;

                    &::placeholder {
                        font-family: $text;
                        color: $neutro-s30;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .helpcenter_tooltip {
        .info {
            max-width: none !important;
            .leftPart {
                @include display-flex();
                @include justify-content(flex-start);
                @include align-items();
                @include flex-wrap(wrap);
                height: auto;
                width: 70%;
                .text {
                    height: auto;
                }
            }

            .rightPart {
                height: auto;
                width: 30%;
                .button {
                    width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .helpcenter_tooltip {
        .info {
            .leftPart {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include flex-wrap(wrap);
                width: 100%;
                margin: 5px 0 10px 0;
            }
            .rightPart {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include flex-wrap(wrap);
                width: 100%;
                margin-bottom: 5px;
                .button {
                    max-width: 300px;
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.ticketForm.portrait {
    padding: 10px 10px 60px 10px;

    .helpcenter_tooltip {
        .info {
            box-shadow: none;
            .left {
                .text {
                    font-family: $text;
                    @include font-size(xs);
                    line-height: 0.875rem;
                }
            }
            .right {
                margin-top: 10px;
                .button {
                    font-family: $text-bold;
                    @include font-size(s);
                }
            }
        }
    }
    .item {
        margin-bottom: 10px;
        .label {
            @include font-size(s);
        }
        .sublabel {
            @include font-size(xs);
        }
        select,
        input,
        textarea {
            width: 100%;
            padding: 10px;
            height: 45px;
            border: 0;
            border-radius: 3px;
            @include font-size(s);
        }

        textarea {
            height: 100px;
        }
    }
}
</style>

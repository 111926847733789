<template>
    <div class="tickets" :class="[$mq]">
        <!-- Form -->
        <template v-if="inForm">
            <ticketForm @cancel="closeForm()"></ticketForm>
        </template>
        <!-- Home -->
        <template v-else>
            <!-- Ticket list -->
            <template v-if="!selectedTicket">
                <div class="main-tickets" :class="{ full: !hasTicketTool }">
                    <div class="andy">
                        <div class="andy-face"></div>
                        <div class="andy-msg">
                            <div class="big-text">{{ $t('tickets.andy.help_title') }}</div>
                            <div class="text" v-html="$t('tickets.andy.help_description')"></div>
                            <div class="action" @click="openHelpCenter">{{ $t('tickets.helpcenter') }}</div>
                        </div>
                    </div>
                </div>

                <div class="open-chat">
                    <div class="label">{{ $t('tickets.need_support') }}</div>
                    <div v-if="chatActive" class="action" :class="{ loading: !chatLoaded }" @click="openTicket()">{{ $t('tickets.open_chat') }}</div>
                    <div class="description" v-html="chatActive ? $t('tickets.text_us') : $t('tickets.or_text_us')"></div>
                </div>
            </template>

            <!-- Ticket detail -->
            <template v-if="selectedTicket">
                <ticketDetail @returnToList="resetSelectedTicket()" @openForm="openForm"></ticketDetail>
            </template>
        </template>
    </div>
</template>

<script>
import ticketItem from '@/components/domain/ticket/item'
import ticketForm from '@/components/domain/ticket/form'
import ticketDetail from '@/components/domain/ticket/detail'
import i18n from '@/i18n'

export default {
    name: 'Tickets',
    components: {
        ticketItem,
        ticketForm,
        ticketDetail
    },
    data() {
        return {
            selectedStatus: 'all',
            inForm: false,
            selectedTicket: false,
            chatLoading: true
        }
    },
    computed: {
        chatActive() {
            return this.$store.getters.getIsChatActive
        },

        chatLoaded() {
            return this.$store.getters['ticket/getChatLoaded']
        },

        location() {
            return this.$store.getters['loginUser/getLocalLocation']
        },

        tickets() {
            return this.$store.getters['ticket/getList']
        },

        filteredTickets() {
            let tickets = {}

            for (let ticketId in this.tickets) {
                let ticket = this.tickets[ticketId]

                // check if ticket status is equal to selected status, or selected status is all
                if (ticket.data.status == this.statusList[this.selectedStatus].key || this.statusList[this.selectedStatus].key == 'all') {
                    tickets[ticket.id] = ticket
                }
            }

            return tickets
        },

        lastTickets() {
            if (this.filteredTickets) {
                var filteredTickets = { ...this.filteredTickets }
                var result = {}
                if (Object.keys(filteredTickets)[0]) {
                    var index0 = Object.keys(filteredTickets)[0]
                    result[index0] = filteredTickets[index0]
                }
                if (Object.keys(filteredTickets)[1]) {
                    var index1 = Object.keys(filteredTickets)[1]
                    result[index1] = filteredTickets[index1]
                }
                return result
            } else {
                return false
            }
        },

        statusList() {
            let status = {
                all: {
                    key: 'all',
                    name: 'tickets.status.all',
                    color: false
                },
                Open: {
                    // EN GESTION
                    key: 'Open',
                    name: 'tickets.status.open',
                    color: '#ABBA37'
                },
                Hold: {
                    // RECIBIDA
                    key: 'Hold',
                    name: 'tickets.status.hold',
                    color: '#475B96'
                },
                Closed: {
                    // RESUELTA
                    key: 'Closed',
                    name: 'tickets.status.closed',
                    color: '#5FC09C'
                }
            }

            return status
        },

        hasTicketTool() {
            const config = this.$store.getters['login/getConfig']
            if (config) {
                var tools = config.tools
                if (tools.includes(9)) {
                    return true
                }
            }
            return false
        },
        isApp() {
            return this.$store.getters['getIsApp']
        }
    },
    methods: {
        loadTickets() {
            var self = this
            this.resetActions()
            this.$store.dispatch('ticket/loadList', { location_id: this.location.id }).then(function() {
                if (self.$route.params.id) {
                    self.inForm = false

                    if (self.$route.params.id != 'all') {
                        self.selectedTicket = self.tickets[self.$route.params.id]
                    }
                } else if (self.$route.name != 'TicketsForm') {
                    self.inForm = false
                    self.selectedTicket = false
                    self.resetActions()
                }
            })
        },
        resetActions() {
            let self = this

            // reset bar acction
            this.$bar.reset()
        },
        closeForm() {
            this.inForm = false
            if (this.selectedTicket) {
                if (this.selectedTicket == 'all') {
                    this.$router.push({
                        name: 'TicketsDetail',
                        params: { id: 'all' }
                    })
                } else
                    this.$router.push({
                        name: 'TicketsDetail',
                        params: { id: this.selectedTicket.id }
                    })
            } else {
                this.$router.push({
                    name: 'Tickets'
                })

                this.resetActions()
            }
        },

        openTicket() {
            var self = this
            if (!fnCheckConnection()) {
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('tickets.chat_no_connection'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function(response) {
                        self.$popup.close()
                    }
                })
            } else {
                if (!this.chatLoading) {
                    document.getElementsByClassName('zsiq_floatmain')[0].click()

                    const oLocation = this.$store.getters['loginUser/getLocalLocation']
                    const oUser = this.$store.getters['loginUser/getUserInformation']
                    const oRole = this.$store.getters['employee/getRol'](oUser.rol)

                    $zoho.salesiq.ready = function() {
                        // console.log('sending params....')
                        $zoho.salesiq.visitor.info({
                            app_version: process.env.VUE_APP_VERSION,
                            site_name: oLocation.name,
                            site_username: oLocation.username,
                            site_email: oLocation.email,
                            account: oLocation.account_name,
                            employee: oUser.name + ' ' + oUser.surname,
                            employee_email: oUser.email,
                            employee_rol: oRole.name + ' (Level ' + oRole.level + ' )'
                        })
                    }
                    $zoho.salesiq.ready()
                }
            }
        },

        openForm() {
            this.$router.push({
                name: 'TicketsForm'
            })
            this.inForm = true
            this.resetActions()
        },

        resetSelectedTicket() {
            this.$router.push({
                name: 'Tickets'
            })
            this.selectedTicket = false
            this.loadTickets()
            this.openTicket(false)
            // this.resetActions()
        },
        openHelpCenter() {
            if (this.isApp) {
                cordova.InAppBrowser.open('https://help.andyapp.io/', '_system')
            } else {
                window.open('https://help.andyapp.io/', '_blank')
            }
        },

        getStatusColor(status) {
            return typeof this.statusList[status] !== 'undefined' ? this.statusList[status].color : this.statusList['Open'].color
        }
    },
    created() {
        // load information from api
        this.loadTickets()
        this.resetActions()
    },
    mounted() {
        if (fnCheckConnection() && this.chatActive && !this.chatLoaded) {
            let Script = document.createElement('script')
            Script.setAttribute('src', (this.$store.getters.getIsApp ? '' : '/') + 'js/chat.js?' + process.env.VUE_APP_VERSION)
            document.head.appendChild(Script)

            Script.onload = () => {
                this.chatLoading = false
                this.$store.commit('ticket/setChatLoaded', true)
            }
        } else if (this.chatActive) {
            this.chatLoading = false
        }
    },
    destroyed() {
        this.$bar.reset()
    },
    watch: {
        // $route: 'loadTickets'
    }
}
</script>

<style lang="scss">
.tickets {
    height: 100%;
    width: 100%;

    .main-tickets,
    .last-tickets,
    .open-chat {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        @include flex-direction(column);
        height: 50%;
        width: 100%;
    }

    .main-tickets {
        &.full {
            height: 80%;
        }

        .andy {
            // @include background($image: img('ellipse_2.svg'), $size: 350px, $position: top 70px center);
            bottom: 0px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                min-width: 300px;
                min-height: 300px;
                max-width: 320px;
                max-height: 320px;
                top: 0;
                left: calc(50% - 300px / 2);
                z-index: -1;
                @include background($image: img('ellipse_1.svg'), $size: 100%);
                transform: rotate(-25deg);
            }

            .andy-face {
                @include background($image: img('alex_carita_06_filled.svg'), $size: 120px, $position: center center);
                width: 120px;
                height: 120px;
                margin: 0 auto;
            }
            .andy-msg {
                .big-text {
                    @include font-size(xl);
                    text-align: center;
                    font-family: $text-bold;
                    color: $main-s30;
                    margin-bottom: 15px;
                }
                .text {
                    @include font-size(m);
                    text-align: center;
                    font-family: $text-light;
                    color: $main-s30;
                    margin-bottom: 17px;
                    b {
                        font-family: $text-bold;
                    }
                }
                .action {
                    @include border-radius(4px);
                    @include font-size(m);
                    text-align: center;
                    font-family: $text-bold;
                    color: #fff;
                    background-color: $main;
                    padding: 9px;
                    width: fit-content;
                    margin: 0 auto;
                    cursor: pointer;
                }
            }
        }
    }

    .last-tickets {
        .tickets-list {
            width: 80%;
            max-width: 800px;
        }
        .label {
            @include font-size(l);
            font-family: $text-bold;
            color: $main-s30;
            margin-bottom: 10px;
        }

        .view-all {
            @include background($image: img('right_arrow_main_s30.svg'), $size: 15px, $position: right center);
            @include font-size(m);
            padding-right: 25px;
            color: $main-s30;
            font-family: $text;
            cursor: pointer;
        }
    }

    .open-chat {
        .label {
            @include font-size(ml);
            text-align: center;
            font-family: $text-bold;
            color: $inactive-s80;
        }

        .action {
            @include font-size(m);
            @include border-radius(4px);
            @include background($image: img('headset.svg'), $size: 20px, $position: left 20px center);
            background-color: $accent-dark;
            color: #fff;
            font-family: $text-bold;
            margin: 30px 0 0;
            padding: 10px 30px;
            padding-left: 55px;
            cursor: pointer;

            &.loading {
                @include background($image: img('loader_light.svg'), $position: center center, $size: 40px);
                pointer-events: none;
                text-indent: -99999px;
                animation: none;
            }
        }

        .description {
            @include font-size(s);
            font-family: $text;
            color: $inactive-s80;
            margin-top: 30px;
        }
    }
}
.zsiq_floatmain {
    display: none !important;
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.tickets.portrait {
    padding: 10px;
    .main-tickets,
    .last-tickets {
        padding: 10px;
        height: auto;
    }

    .main-tickets {
        .andy {
            &::after {
                min-width: 200px;
                min-height: 200px;
                width: 100%;
            }

            .andy-msg {
                .big-text {
                    @include font-size(ml);
                    margin-bottom: 5px;
                }
                .text {
                    @include font-size(s, $important: true);
                    margin-bottom: 5px;
                }
            }
        }
    }

    .last-tickets {
        padding-bottom: 60px;
        @include flex-wrap(wrap);
        @include flex-direction(initial);

        .label {
            @include font-size(m);
            text-align: center;
        }

        .tickets-list {
            width: 100%;
        }

        .view-all {
            @include font-size(xs);
            text-transform: uppercase;
            padding: 15px;
            border-radius: 3px;
            width: 100%;
            text-align: center;
            background-position: right 25% center;
            background-color: $main-t80;
            @include background($image: img('right_neutro.svg'), $size: 13px, $position: right 15px center);
            background-color: $default-sec-color;
        }
    }
}
</style>
